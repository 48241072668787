<template>
  <div class="layout">
    <Layout class="layout-wrapper">
      <Sider ref="menuSider" hide-trigger collapsible :collapsed-width="90" v-model="isCollapsed">
        <header class="menu-header">
          <svg class="ali-icon" aria-hidden="true">
            <use xlink:href="#icon-safe" />
          </svg>
          <!-- <transition name="title-fade">
            <h1 v-if="!isCollapsed" class="header-title">{{headerText}}</h1>
          </transition> -->
        </header>
        <Menu
          ref="menu"
          @on-select="changeRoute"
          :active-name="menuDefaultActive"
          :class="menuitemClasses"
          :open-names="[menu[0].name]"
          theme="light"
          width="auto"
        >
          <template v-for="item in menu">
            <transition name="menu-fade" :key="item.path">
              <div :key="item.path">
                <Submenu class="sub-menu-item" v-if="item.children && item.children.length > 0" :name="item.name">
                  <template slot="title">
                    <Dropdown v-if="isCollapsed" @on-click="changeRoute" placement="right-start">
                      <Icon :type="item.icon"></Icon>
                      <DropdownMenu slot="list">
                        <DropdownItem v-for="menuItem in item.children" :name="menuItem.name" :key="menuItem.path">{{
                          menuItem.meta.title
                        }}</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Icon v-else :type="item.icon"></Icon>

                    <span>{{ item.meta.title }}</span>
                  </template>
                  <MenuItem v-for="menuItem in item.children" :name="menuItem.name" :key="menuItem.path"
                    ><span class="sub-menu-span">{{ menuItem.meta.title }}</span></MenuItem
                  >
                </Submenu>
                <MenuItem v-else :name="item.name">
                  <Dropdown v-if="isCollapsed" @on-click="changeRoute" placement="right-start">
                    <Icon :type="item.icon"></Icon>
                    <DropdownMenu slot="list">
                      <DropdownItem :name="item.name">{{ item.meta.title }}</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Icon v-else :type="item.icon"></Icon>
                  <Icon :type="item.icon"></Icon>
                  <span>{{ item.meta.title }}</span>
                </MenuItem>
              </div>
            </transition>
          </template>
        </Menu>
      </Sider>
      <Layout>
        <Header class="layout-header-bar">
          <div class="left-wrapper">
            <Icon @click.native="collapsedSider" :class="rotateIcon" type="md-menu" size="24"></Icon>
            <Breadcrumb class="Breadcrumb">
              <BreadcrumbItem v-for="(item, index) in routerMeta" :key="index">{{ item }}</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div class="right-wrapper">
            <div class="header-menu-item user-wrapper">
              <Dropdown @on-click="dropMenuClick">
                <div class="avatar-wrapper">
                  <svg class="ali-icon" aria-hidden="true">
                    <use xlink:href="#icon-user1" />
                  </svg>
                  <span class="user-name">{{ userInfo ? `${userInfo.loginCode} (${userInfo.unitName})` : '--' }}</span>
                </div>
                <DropdownMenu slot="list">
                  <DropdownItem name="logout">注销</DropdownItem>
                  <DropdownItem name="changePassword">修改密码</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <!-- <div class="header-menu-item">
              <Badge :count="2" class="notice-wrapper">
                <Icon type="ios-notifications-outline" size="26"></Icon>
              </Badge>
            </div> -->
            <!-- <div class="header-menu-item setting-wrapper">
              <Icon type="md-more" size="20"/>
            </div> -->
          </div>
          <div v-if="isShowMenuTabs" ref="scroll" class="menu-tabs">
            <div class="scroll-content">
              <span
                v-for="(item, index) in cacheRoutes"
                @click="changeRoute(item.name)"
                :class="currentRoute == item.name ? 'tab-item current-item' : 'tab-item'"
                :key="item.name"
              >
                <span>{{ item.meta.title }}</span>
                <Icon @click.stop="checkRoutePageEditting(item, index)" type="md-close" />
              </span>
            </div>
          </div>
        </Header>
        <Content class="content-wrapper" :class="isShowMenuTabs ? 'content-padding' : ''">
          <router-view></router-view>
        </Content>
      </Layout>
    </Layout>
    <Modal v-model="passwordModal" title="修改密码" :footer-hide="true">
      <div class="form-wrapper">
        <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="80">
          <FormItem label="旧密码" prop="oldPwd">
            <Input type="password" v-model="formCustom.oldPwd"></Input>
          </FormItem>
          <FormItem label="新密码" prop="newPwd">
            <Input type="password" v-model="formCustom.newPwd"></Input>
          </FormItem>
          <FormItem label="确认密码" prop="passwdCheck">
            <Input type="password" v-model="formCustom.passwdCheck"></Input>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="changePassword" :loading="setPwdLoading">确认</Button>
        </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import axios from 'axios'
import { removeUserInfo, getDefaultRouteForStorage } from '@/assets/js/sessionStorage.js'
import { mapState, mapMutations } from 'vuex'
import { privilegeMixin, userInfoMixin } from '@/assets/js/mixin.js'
import BScroll from '@better-scroll/core'
export default {
  mixins: [privilegeMixin, userInfoMixin],
  data() {
    const validatePass = (rule, value, callback) => {
      let regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])')
      if (value === '') {
        callback(new Error('请输入密码!'))
      } else if (value.trim() === 'qwe@123..') {
        callback(new Error('不能与初始密码相同'))
      } else if (value.trim().length < 8) {
        callback(new Error('密码必须大于8位'))
      } else if (!regex.test(value)) {
        callback(new Error('密码必须包含数字和字母'))
      } else {
        if (this.formCustom.passwdCheck !== '') {
          // 对第二个密码框单独验证
          this.$refs.formCustom.validateField('passwdCheck')
        }
        callback()
      }
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码!'))
      } else if (value !== this.formCustom.newPwd) {
        callback(new Error('两次输入密码不匹配，请重新输入!'))
      } else {
        callback()
      }
    }
    return {
      menu: [], // 菜单信息
      isCollapsed: false,
      menuDefaultActive: 'notification',
      isShowOtherMenu: false,
      routerMeta: [],
      isShowMenuTabs: true,
      currentRoute: '', // 当前路由name
      menuScrollObj: null, // menu tabs scroll对象
      passwordModal: false,
      setPwdLoading: false,
      formCustom: {
        userId: '',
        oldPwd: '',
        newPwd: '',
        passwdCheck: '',
        appKey: '',
      },
      ruleCustom: {
        oldPwd: [{ required: true, message: '旧密码不能为空!', trigger: 'blur' }],
        newPwd: [{ validator: validatePass, required: true, trigger: 'blur' }],
        passwdCheck: [{ validator: validatePassCheck, required: true, trigger: 'blur' }],
      },
    }
  },
  computed: {
    ...mapState({
      cacheRoutes: 'cacheRoutes',
      pageEditStatus: 'pageEditStatus',
    }),
    rotateIcon() {
      return ['menu-icon', this.isCollapsed ? 'rotate-icon' : '']
    },
    menuitemClasses() {
      return ['menu-item', this.isCollapsed ? 'collapsed-menu' : '']
    },
    headerText: function() {
      return WEB_CONFIG.header ? WEB_CONFIG.header : '道路运输企业隐患治理系统'
    },
  },
  watch: {
    $route: {
      handler: function(currentRoute) {
        this.changeRouteInfo(currentRoute)
      },
      deep: true,
    },
    cacheRoutes: {
      handler: function() {
        // menu tabs数量改变 则刷新menuScrollObj
        if (this.menuScrollObj) {
          this.menuScrollObj.refresh()
        }
      },
    },
  },
  created() {
    this.initRouter()
    this.checkRoutePath()
    this.getCurrentPagePrivilegeInfo()
  },
  mounted() {
    this.initMenuScroll()
  },
  methods: {
    ...mapMutations({
      setCacheRoutes: 'setCacheRoutes',
      setBtnPrivilegeCode: 'setBtnPrivilegeCode'
    }),

    // 初始化路由信息
    initRouter () {
      let menuInfo = this.userInfo.menus.menuViews
      let routes = this.getChildrenRoute(menuInfo)
      this.$router.addRoutes([
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/layout/index.vue'),
          children: routes,
        }
      ])
      this.menu = routes
    },
    // 获取子路由
    getChildrenRoute(menuInfo) {
      let routes = []
      menuInfo.forEach(item => {
        if (item.permission) {
          // 有路由信息 则添加到数组
          let currentRoute = {
            path: item.permission,
            name: item.code,
            icon: item.icon,
            meta: {
              title: item.name,
              breadcrumb: item.menudescribe ? item.menudescribe.split('/') : []
            },
            children: [],
            component: () => import(`@/views${item.component}`),
          }
          // if (item.name == 'dailyWork') {
          //   // 日常工作的重定向
          //   currentRoute['redirect'] = '/dailyWork/notification'
          // }
          if (item.children.length) {
            currentRoute.children.push(...this.getChildrenRoute(item.children))
          }
          routes.push(currentRoute)
        }
      })
      return routes
    },
    // 判断首次页面跳转逻辑
    checkRoutePath () {
      let defaultRoute = getDefaultRouteForStorage()
      if (defaultRoute) {
        this.$router.push({path: defaultRoute.path})
      } else {
        this.$router.push({name: this.menu[0].children[0].name})
      }
    },

    // 获取每个页面的权限按钮信息
    // 获取该页权限信息
    getCurrentPagePrivilegeInfo() {
      let btnPrivilegeCode = {}
      let menuInfo = this.userInfo.menus.menuViews
      this.getChildrenMenuInfo(menuInfo, btnPrivilegeCode)
      this.setBtnPrivilegeCode(btnPrivilegeCode)
    },
    getChildrenMenuInfo (menuInfo, btnPrivilegeCode) {
      menuInfo.forEach(menuItem => {
        if (menuItem.operations && menuItem.operations.length > 0) {
          btnPrivilegeCode[menuItem.code] = menuItem.operations.map(item => item.code)
        }
        if (menuItem.children.length > 0) {
          this.getChildrenMenuInfo(menuItem.children, btnPrivilegeCode)
        }
      })
    },

    // 改变路由显示信息
    changeRouteInfo (currentRoute) {
      this.currentRoute = currentRoute.name
      this.menuDefaultActive = currentRoute.name
      this.routerMeta = currentRoute.meta.breadcrumb
      let targetCacheRoute = this.cacheRoutes.find(item => item.name == currentRoute.name)
      if (!targetCacheRoute && !currentRoute.meta.hiddlen) {
        // 如果该route没缓存 则添加到缓存列表
        this.setCacheRoutes([...this.cacheRoutes, currentRoute])
      }
    },
    // 初始化menu scroll
    initMenuScroll() {
      this.$nextTick(() => {
        this.menuScrollObj = new BScroll(this.$refs.scroll, {
          scrollX: true,
          click: true,
          scrollbar: true,
        })
      })
    },
    collapsedSider() {
      this.$refs.menuSider.toggleCollapse()
    },
    changeRoute(item) {
      if (this.$route.name == item) {
        // 重复跳转
        return
      }
      this.$router.push({ name: item })
    },
    // 关闭页面当前是否为编辑状态
    checkRoutePageEditting(item, index) {
      if (this.pageEditStatus[item.name]) {
        // 是编辑状态
        this.$Modal.confirm({
          title: '关闭缓存',
          content: `关闭 ${item.meta.title} 页面缓存标签会导致该页面编辑进度丢失,是否继续？`,
          onOk: async () => {
            this.removeRoute(item, index)
          },
          onCancel: () => {},
        })
      } else {
        this.removeRoute(item, index)
      }
    },
    // 清除缓存route
    removeRoute(item, index) {
      let _routes = [...this.cacheRoutes]
      _routes.splice(index, 1)
      this.setCacheRoutes(_routes)
      if (item.name == this.currentRoute) {
        // 如果是删除的当前route 则重新定位到最后一个route
        if (_routes.length > 0) {
          this.currentRoute = _routes[_routes.length - 1].name
        }
      }
    },

    // header下拉菜单选择
    dropMenuClick(name) {
      if (name == 'logout') {
        this.logout()
      } else if (name == 'changePassword') {
        this.passwordModal = true
        this.formCustom.newPwd = ''
      }
    },

    // 登出
    logout() {
      removeUserInfo()
      this.setCacheRoutes([])
      this.$router.push('/login')
    },
    // 修改密码
    changePassword() {
      this.$refs.formCustom.validate(valid => {
        if (valid) {
          this.setPassword()
        } else {
          this.$Message.error('表单未完成或有错，请重新填写!')
        }
      })
    },
    async setPassword () {
      let _params = {
        userId: this.userInfo.userId,
        oldPwd: this.formCustom.oldPwd,
        newPwd: this.formCustom.newPwd,
        appKey: this.userInfo.appKey
      }
      this.setPwdLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/baseInfo/UserInfo/updateUserPwd`,
          data: _params
        })
        this.setPwdLoading = false
        if (data.code === 200) {
          this.$Message.success('修改成功!')
          this.passwordModal = false
          this.logout()
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.setPwdLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('密码修改失败失败, 请稍后重试!')
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.layout {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  background: #f5f7f9;
  overflow: hidden;
  .layout-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .menu-header {
    position: relative;
    height: 64px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f8f8f9;
    .ali-icon {
      line-height: 50px;
      font-size: 36px;
    }
    .header-title {
      line-height: 50px;
      font-size: 12px;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 10px;
      font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
    }
  }
  .layout-header-bar {
    position: sticky;
    width: 100%;
    top: 0px;
    padding: 0px;
    background: #fff;
    border-bottom: 0.5px solid rgba(0, 21, 41, 0.08);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    .left-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 20px;
      .Breadcrumb {
        margin-left: 20px;
      }
    }
    .right-wrapper {
      position: relative;
      height: 100%;
      margin-right: 20px;
      display: flex;
      .header-menu-item {
        position: relative;
        height: 100%;
        padding: 0 15px;
        background: #fff;
        transition: background 0.5s;
        border-bottom: 0.5px solid rgba(0, 21, 41, 0.08);
        &:hover {
          cursor: pointer;
          background: #f5f5f5;
          transition: background 0.5s;
        }
      }
      .platform-wrapper {
        color: #03a9f4;
        display: flex;
        align-content: center;
        i {
          margin: auto 0;
        }
        .platform-name {
          margin-left: 5px;
          max-width: 200px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .user-wrapper {
        .avatar-wrapper {
          .ali-icon {
            font-size: 24px;
          }
          .user-name {
            margin-left: 5px;
            color: #757575;
          }
        }
      }
      .notice-wrapper {
        line-height: 32px;
      }
    }
  }
  .menu-tabs {
    position: absolute;
    height: 30px;
    width: 100%;
    top: 64px;
    background-color: #fff;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
    .scroll-content {
      display: inline-block;
      padding: 0 20px;
      .tab-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        height: 26px;
        line-height: 26px;
        padding: 0 5px;
        margin: 2px 5px 2px 0px;
        font-size: 12px;
        border: 1px solid #d8dce5;
        color: #495060;
        transition: padding 0.3s;
        &:hover {
          cursor: pointer;
          padding: 0 7px;
          transition: padding 0.3s;
        }
        span {
          line-height: 26px;
          font-size: 12px;
        }
        i {
          margin-left: 3px;
          transition: transform 0.3s;
          &:hover {
            color: #f44336;
            transform: rotate(180deg);
            transition: transform 0.3s;
          }
        }
      }
      .current-item {
        background-color: #42b983;
        border-color: #42b983;
        color: #fff;
        transition: all 0.3s;
      }
    }
  }
  .content-wrapper {
    position: relative;
    padding: 20px;
  }
  .content-padding {
    padding-top: 40px;
  }

  .layout-logo-left {
    width: 90%;
    height: 30px;
    background: rgb(48, 65, 86);
    border-radius: 3px;
    margin: 15px auto;
  }
  .ivu-layout-sider {
    background: #fff;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    z-index: 11;
    .ivu-menu {
      &::after {
        width: 0px;
      }
    }
  }
  .menu-icon {
    transition: all 0.3s;
  }
  .rotate-icon {
    transform: rotate(-90deg);
  }
  .menu-item {
    .sub-menu-item {
      ul {
        height: auto;
      }
    }
    span {
      display: inline-block;
      overflow: hidden;
      width: 100px;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: bottom;
      transition: width 0.2s ease 0.2s;
    }
    i {
      transform: translateX(0px);
      transition: font-size 0.2s ease, transform 0.2s ease;
      font-size: 18px;
      margin-right: 15px;
    }
  }
  .collapsed-menu {
    span {
      width: 0px;
      transition: width 0.2s ease;
    }
    .sub-menu-span {
      width: 0px;
      transition: width 0s ease;
    }
    i {
      transform: translateX(5px);
      transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
      font-size: 22px;
    }
  }
}
.title-fade-enter-to,
.title-fade-leave {
  opacity: 1;
  transition: opacity 0.5s;
}

.menu-fade-enter,
.menu-fade-leave-to {
  opacity: 0;
  transition: all 0.5s;
}
.menu-fade-enter-to,
.menu-fade-leave {
  opacity: 1;
  transition: all 0.5s;
}
</style>
